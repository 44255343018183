import PropTypes from 'prop-types';
import React from 'react';
import TextSection from 'components/contentBlocks/TextSection';
import { cmsTextContentProp } from 'utils/proptypes/modules/textProps';
import transformTextSection from 'libs/wordpress/utils/transfromTextSection';

const TextBlock = ({ columnBase = 12, columnSizes = 12, content = [], settings = {}, ...rest }) => {
    const { text_alignment: textAlignment } = settings;

    if (content?.length < 1) {
        return null;
    }

    const transformedContent = transformTextSection(content);

    // Settings for read more wrapper
    const readMoreSettings = {
        readMoreSectionsMobile:
            (settings?.read_more_sections_mobile && parseInt(settings.read_more_sections_mobile, 10)) || undefined,
        readMoreSectionsDesktop:
            (settings?.read_more_sections_desktop && parseInt(settings.read_more_sections_desktop, 10)) || undefined,
    };

    return (
        <TextSection
            columnBase={columnBase}
            columnSizes={columnSizes}
            readMoreSettings={readMoreSettings}
            textAlignment={textAlignment}
            textSections={transformedContent}
            {...rest}
        />
    );
};

TextBlock.propTypes = {
    columnBase: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    columnSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    content: cmsTextContentProp,
    settings: PropTypes.object,
};

export default TextBlock;
