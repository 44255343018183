import { backgroundFlexibleProp, backgroundTypeProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';
import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import BackgroundSwitch from 'components/background/BackgroundSwitch';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { above } from 'utils/mediaqueries';
import contentSpacing from 'config/theme/contentSpacing';
import styled from 'libs/styled';
import Link from 'components/base/Link';

const Div = styled('div')``;

const InnerWrapper = styled('div', { shouldForwardProp: prop => ['centerContent'].indexOf(prop) === -1 })`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    ${({ centerContent }) =>
        centerContent &&
        `
        justify-content: center;
        align-items: center;
  
    `}
`;

const Content = styled('div', {
    shouldForwardProp: prop => ['centerContent'].indexOf(prop) === -1,
})`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 32px;

    ${above.tabletSm} {
        width: calc(66.66666667% - 8px);
    }

    ${above.desktopSm} {
        width: calc(50% - 8px);
        margin-bottom: 40px;
    }

    ${({ centerContent }) =>
        centerContent &&
        `
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        text-align: center;
    `}
`;

/**
 *  contentBlock used to display heading, tag and background with a link.
 *
 * @param {object} background - Background object.
 * @param {string} backgroundType - Type of background if not supplied inside the background object.
 * @param {string} button - Button displayed below text.
 * @param {string} [centerContent=false] - Option to position content in the center.
 * @param {string} heading - Heading as a string.
 * @param {string||string[]} headingFontKeys - Fontkeys for heading.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {array} mediaQuery - Array of media-queries used by background image.
 * @param {string} tag - Small tag displayed below heading.
 * @param {string || string[]} text - WYSIWYG displayed between heading and button.
 */

const CallToAction = ({
    background = {},
    backgroundType = '',
    button = {},
    centerContent = false,
    heading = '',
    headingFontKeys = '',
    headingTag = '',
    mediaQuery = {},
    tag = '',
    text = '',
    ...rest
}) => {
    const hasText = text?.[0] !== '';

    return (
        <Div height="100%" position="relative" width="100%" {...rest}>
            <Link to={button?.to}>
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery}>
                    <InnerWrapper centerContent={centerContent} px={contentSpacing}>
                        <Content centerContent={centerContent}>
                            <Tag marginBottom="8px">{tag}</Tag>
                            <Heading as={headingTag} fontKeys={headingFontKeys} margin="0">
                                {heading}
                            </Heading>
                            {hasText && (
                                <Div marginTop="8px">
                                    <Wysiwyg
                                        data={text}
                                        textComponent={props => (
                                            <WysiwygParagraph fontKeys="NB International/16_120" {...props} />
                                        )}
                                    />
                                </Div>
                            )}
                            {button?.label && button?.to && (
                                <ThemeButton marginTop="24px" theme={button.theme}>
                                    {button.label}
                                </ThemeButton>
                            )}
                        </Content>
                    </InnerWrapper>
                </BackgroundSwitch>
            </Link>
        </Div>
    );
};

CallToAction.propTypes = {
    activeAnimation: PropTypes.bool,
    background: backgroundFlexibleProp,
    backgroundType: backgroundTypeProp.isRequired,
    button: PropTypes.object,
    centerContent: PropTypes.bool,
    fixedContentWidth: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    headingTag: headingTagProp.isRequired,
    mediaQuery: mediaQueryProp.isRequired,
    showScrollButton: PropTypes.bool,
    tag: PropTypes.string.isRequired,
    text: wysiwygProp,
    to: PropTypes.string,
};

export default CallToAction;
