import PropTypes from 'prop-types';
import React from 'react';
import { asArray } from 'utils/array';
import styled from 'libs/styled';

const Div = styled('div')``;

// These functions are also used in ColumnsScrollLayout
export const getRowGutter = gutterWidth => asArray(gutterWidth).map(width => (width ? `-${width / 2}px` : null));
export const getRowAfter = gutterHeight => asArray(gutterHeight).map(height => (height ? `-${height}px` : null));
export const getColumnGutter = gutterWidth => asArray(gutterWidth).map(width => (width ? `${width / 2}px` : null));
export const getColumnAfter = gutterHeight => asArray(gutterHeight).map(height => (height ? `${height}px` : null));

/**
 * Display an array of components in a column layout based on columnQuantity.
 * If the number of items (components) is greater than columnQuantity the columns will wrap to a new row.
 *
 * @version 2.0
 *
 * @param {node[]} blocks - An array of JSX components to render inside each column. Set size prop to overwrite columnSizes.
 * @param {number} columnBase - The base number of columns used. As default 12 is the base.
 * @param {number||number[]} columnSizes - The size of each column. Will be calculated as "columnSizes / columnBase" for percentage.
 * @param {number||number[]} block[x].size - Overwrite columnSizes for specific block
 * @param {number||number[]} block[x].order - Customize the order of specific block
 * @param {number||number[]}  gutterHeight - The distance after each block. The number will be interpreted as "px".
 * @param {number||number[]}  gutterWidth - The distance between each column. The number will be interpreted as "px".
 */

const ColumnsLayout = ({
    blocks = [],
    columnBase = 12,
    columnSizes = [12, null, 6, null, 4, null, 3],
    gutterHeight = [8, null, 16],
    gutterWidth = [8, null, 16],
    style = {},
}) => {
    const columnBaseArray = asArray(columnBase);
    const rowGutter = getRowGutter(gutterWidth);
    const rowAfter = getRowAfter(gutterHeight);
    const columnGutter = getColumnGutter(gutterWidth);
    const columnAfter = getColumnAfter(gutterHeight);

    const rowStyles = {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        mx: rowGutter, // margin-left/-right
        mb: rowAfter, // margin-bottom
        ...style,
    };

    return (
        <Div {...rowStyles}>
            {blocks?.map((Component, i) => {
                if (!Component) {
                    return null;
                }

                const columnsSize = Component.props.size || columnSizes;
                const customColumnStyles = Component.props.columnStyles || {};
                const columnsSizeAsArray = asArray(columnsSize);
                let currentColumnBase = columnBaseArray[0];

                const columnWidths = columnsSizeAsArray.reduce(
                    (acc, colWidth, i) => {
                        currentColumnBase = columnBaseArray[i] || currentColumnBase;
                        const maxWidth = colWidth ? `${(colWidth / currentColumnBase) * 100}%` : null;
                        const width = colWidth ? `${(colWidth / currentColumnBase) * 100}%` : null;
                        const flex = colWidth ? `0 0 ${(colWidth / currentColumnBase) * 100}%` : null;

                        return {
                            maxWidth: [...acc.maxWidth, maxWidth],
                            width: [...acc.width, width],
                            flex: [...acc.flex, flex],
                        };
                    },
                    { maxWidth: [], width: [], flex: [] }
                );

                const columnStyles = {
                    px: columnGutter, // padding-left/-right
                    mb: columnAfter, // margin-bottom
                    order: Component.props.order || 1,
                    ...columnWidths,
                    ...customColumnStyles,
                };

                return (
                    <Div key={`${i}-${Component.key}`} {...columnStyles}>
                        {Component}
                    </Div>
                );
            })}
        </Div>
    );
};

ColumnsLayout.propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.node),
    columnBase: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    columnSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    style: PropTypes.object,
};

export default ColumnsLayout;
