import CallToAction from 'components/contentBlocks/CallToAction';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { mediaQueries } from 'config/theme/breakpoints';
import transformButton from 'libs/wordpress/utils/transformButton';
import transformButtonTheme from 'libs/wordpress/utils/transformButtonTheme';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import transformTextColor from 'libs/wordpress/utils/transformTextColor';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const CallToActionBlock = ({ content = {}, isColumn = false }) => {
    const {
        background_media_flexible: backgroundFlexible,
        button,
        content_position: contentPosition,
        heading,
        tag,
        text_color: textColor,
        text,
    } = content;

    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const background = isAboveDesktopSm ? transformedBackground?.desktop : transformedBackground?.mobile;
    const backgroundType = isAboveDesktopSm
        ? transformedBackground?.desktop?.type
        : transformedBackground?.mobile?.type;

    const transformedButton = button ? transformButton(button) : null;
    const transformedButtonWithTheme = button
        ? {
              ...transformedButton,
              theme: transformButtonTheme(transformedButton.theme, textColor),
          }
        : null;

    return (
        <CallToAction
            background={background}
            backgroundType={backgroundType}
            button={transformedButtonWithTheme}
            centerContent={contentPosition === 'center'}
            color={transformTextColor(textColor)}
            heading={heading ? decodeHTMLEntities(heading.text) : null}
            headingFontKeys={
                isColumn
                    ? ['Recife Display/32_120', null, 'Recife Display/56', null, 'Recife Display/48']
                    : ['Recife Display/32_120', null, 'Recife Display/56']
            }
            headingTag={heading ? heading.type : null}
            mediaQuery={mediaQueries.maxWidth}
            tag={tag}
            text={text}
        />
    );
};

CallToActionBlock.propTypes = {
    content: PropTypes.shape({
        'background_media_flexible': PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }).isRequired,
        button: PropTypes.object,
        'content_position': PropTypes.string,
        heading: PropTypes.object,
        tag: PropTypes.string,
        text: wysiwygProp,
        'text_color': PropTypes.string,
    }),
    isColumn: PropTypes.bool,
};

export default CallToActionBlock;
