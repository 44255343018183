import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';

const ImageRatioBlock = ({ content = {} }) => {
    const { image_ratio: imageRatio } = content;

    const transformedBackground = transformBackgroundImage(imageRatio.background_image);
    const ratio = imageRatio.ratio === 'portrait' ? ratios.vertical : ratios.horizontal;

    if (!transformedBackground) {
        return null;
    }

    return (
        <AspectWrapper ratio={ratio}>
            <BackgroundImage
                src={transformedBackground.url}
                position={transformedBackground.position}
                size={transformedBackground.backgroundSize}
                query={mediaQueries.fullWidth}
            />
        </AspectWrapper>
    );
};

ImageRatioBlock.propTypes = {
    content: PropTypes.shape({
        'image_ratio': PropTypes.exact({
            'background_image': PropTypes.object,
            ratio: PropTypes.string,
        }),
    }).isRequired,
};

export default ImageRatioBlock;
