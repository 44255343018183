import BackgroundImage from 'components/background/BackgroundImage';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const ImageFullscreenBlock = ({ content = {} }) => {
    const { background_portrait_landscape: backgroundPortraitLandscape } = content;

    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const transformedBackground = isAboveDesktopSm
        ? transformBackgroundImage(backgroundPortraitLandscape.landscape?.background_image)
        : transformBackgroundImage(backgroundPortraitLandscape.portrait?.background_image);

    if (!transformedBackground) {
        return null;
    }

    return (
        <BackgroundImage
            src={transformedBackground.url}
            position={transformedBackground.position}
            size={transformedBackground.backgroundSize}
            query={mediaQueries.fullWidth}
        />
    );
};

ImageFullscreenBlock.propTypes = {
    content: PropTypes.shape({
        'background_portrait_landscape': PropTypes.exact({
            'landscape': PropTypes.object,
            'portrait': PropTypes.object,
        }),
    }).isRequired,
};

export default ImageFullscreenBlock;
