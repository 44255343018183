/**
 * Transforms data from text-section-repeater
 * @param {object[]} data - Array of text-section objects.
 * @param {object[]} - Transformed link data.
 */

export default data => {
    // Just return undefined if we don't have any data
    if (!data) {
        return undefined;
    }

    // Return the transformed data.
    return data.map(textSection => {
        const { layout } = textSection;
        if (layout === 'heading' || layout === 'sub_heading' || layout === 'sub-heading') {
            return {
                heading: textSection.heading.text,
                headingTag: textSection.heading.type,
                horizontalAlignment: textSection.horizontal_alignment,
                layout: layout === 'sub_heading' || layout === 'sub-heading' ? 'subHeading' : 'heading',
            };
        }

        // Layout === paragraph or preamble
        return {
            text: textSection['paragraph'],
            horizontalAlignment: textSection.horizontal_alignment,
            layout,
        };
    });
};
