import colors from 'config/theme/colors';

const colorOptions = {
    black: colors.black,
    white: colors.white,
};
/**
 * @param {string} color = Color name as string
 */

export default (color: string) => colorOptions[color] || colorOptions.black;
