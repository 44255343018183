import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import TextBlock from 'libs/wordpress/content/cmsBlocks/TextBlock';
import moduleSpacing from 'config/theme/moduleSpacing';

const TextLayout = ({ isColumn = false, ...rest }) => (
    <MaxWidthWrapper as="section" includeContentSpacing={!isColumn} py={moduleSpacing.sm}>
        <TextBlock columnSizes={isColumn ? 12 : [12, null, 10, null, 6]} {...rest} />
    </MaxWidthWrapper>
);

TextLayout.propTypes = {
    isColumn: PropTypes.bool,
};

export default TextLayout;
