import BackgroundVideo from 'components/base/video/BackgroundVideo';
import PropTypes from 'prop-types';
import React from 'react';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const VideoInlineFullscreenBlock = ({ content = {} }) => {
    const { background_video_portrait_landscape: backgroundVideoPortraitLandscape } = content;

    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const video = isAboveDesktopSm
        ? backgroundVideoPortraitLandscape.landscape?.video
        : backgroundVideoPortraitLandscape.portrait?.video;

    if (!video) {
        return null;
    }

    return <BackgroundVideo src={video.url} />;
};

VideoInlineFullscreenBlock.propTypes = {
    content: PropTypes.shape({
        'background_video_portrait_landscape': PropTypes.exact({
            landscape: PropTypes.object,
            portrait: PropTypes.object,
        }),
    }).isRequired,
};

export default VideoInlineFullscreenBlock;
