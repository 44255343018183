import React, { useEffect, useRef, useState } from 'react';

import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import ShowMoreWrapper from 'components/wrappers/ShowMoreWrapper';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import debounce from 'lodash/debounce';
import getElementsHeights from 'utils/getElementsHeights';
import styled from 'libs/styled';
import { textContentProp } from 'utils/proptypes/modules/textProps';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const Text = styled(WysiwygParagraph)`
    a {
        color: inherit;
    }
`;
const Div = styled('div')``;
const TextBlocksWrapper = styled('div')``;

/**
 * Display a large block with a image or video as a background and a revolving text in blendmode as a foreground.
 *
 * @param {object} readMoreSettings - Settings for read more wrapper.
 * @param {object[]} textSections - Array of objects.
 * @param {string} textSections.layout - The type of content that should be display; heading, subHeading, ingress or paragraph.
 * @param {string} textSections.heading - The heading as a string. Used by heading and subHeading.
 * @param {string} textSections.headingTag - The html-tag used by heading and subHeading; h1, h2, h3 etc.
 * @param {array} textSections.text - Text as a wysiwyg-array. Used by ingress and paragraph.
 * @param {string} textAlignment - Text placement (left, center, right) as string.
 *
 */

const TextSection = ({
    columnBase = 12,
    columnSizes = 12,
    gutterHeight = 0,
    gutterWidth = [8, null, 16],
    readMoreSettings = {},
    textAlignment = 'center',
    textSections = [],
    ...rest
}) => {
    if (!textSections.length > 0) {
        return null;
    }

    const customMargin = {
        sm: '8px 0 0',
        md: ['48px 0 0', null, null, null, '64px 0 0'],
    };

    let alignItems = textAlignment;

    if (textAlignment === 'left') {
        alignItems = 'flex-start';
    }

    if (textAlignment === 'right') {
        alignItems = 'flex-end';
    }

    const hasReadMoreSettings =
        readMoreSettings.readMoreSectionsMobile || readMoreSettings.readMoreSectionsDesktop ? true : false;

    const [readMoreHeight, setReadMoreHeight] = useState({ 'maxHeightMobile': 0, 'maxHeightDesktop': 0 });
    const isDesktop = useAboveBreakpoint('desktopMd');

    const wrapperRef = useRef(null);

    const updateReadMoreHeight = () => {
        const node = wrapperRef.current;

        if (node && node.children?.length > 0 && hasReadMoreSettings) {
            const { readMoreSectionsMobile, readMoreSectionsDesktop } = readMoreSettings;
            // if we have no children for mobile or desktop we default to 0 which will always show the whole wrapper
            let desktopHeight = 0;
            let mobileHeight = 0;
            if (readMoreSectionsDesktop && node.children?.length >= readMoreSectionsDesktop - 1) {
                // Elements that should always be visible on desktop
                const desktopChildren = Array.from(node.children).slice(0, readMoreSectionsDesktop);

                desktopHeight = getElementsHeights(desktopChildren);
            }
            if (readMoreSectionsMobile && node.children?.length >= readMoreSectionsMobile - 1) {
                // Elements that should always be visible on mobile
                const mobileChildren = Array.from(node.children).slice(0, readMoreSectionsMobile);

                mobileHeight = getElementsHeights(mobileChildren);
            }
            // We only update the state if the height has actually changed(example when resizing and text gets a new row)
            if (
                (!isDesktop && mobileHeight !== parseInt(readMoreHeight.maxHeightMobile, 10)) ||
                (isDesktop && desktopHeight !== parseInt(readMoreHeight.maxHeightDesktop, 10))
            ) {
                setReadMoreHeight({ 'maxHeightMobile': mobileHeight, 'maxHeightDesktop': desktopHeight });
            }
        }
    };

    // Add a resize listener that updates height for the elements in the show more wrapper
    useEffect(() => {
        // delay for the resize event
        const debounceResizeHandler = debounce(updateReadMoreHeight, 100);

        if (hasReadMoreSettings) {
            window.addEventListener('resize', debounceResizeHandler);
        }
        return () => {
            if (hasReadMoreSettings) {
                window.removeEventListener('resize', debounceResizeHandler);
            }
        };
    }, [hasReadMoreSettings, readMoreHeight.maxHeightMobile, readMoreHeight.maxHeightDesktop, isDesktop]);

    useEffect(() => {
        updateReadMoreHeight();
    }, [wrapperRef]);

    const Component = hasReadMoreSettings ? ShowMoreWrapper : Div;
    const readMoreProps = hasReadMoreSettings ? { 'textAlign': 'center', ...readMoreHeight } : {};

    return (
        <>
            <ColumnsLayout
                columnBase={columnBase}
                columnSizes={columnSizes}
                gutterHeight={gutterHeight}
                gutterWidth={gutterWidth}
                style={{ flexDirection: 'column', alignItems, ...rest }}
                blocks={[
                    <Component {...readMoreProps}>
                        <TextBlocksWrapper ref={wrapperRef} textAlign="left">
                            {textSections.map((section, i) => {
                                const { layout, heading, headingTag, text } = section;
                                const key = `${layout}-${i}`;
                                const firstSection = i === 0;

                                switch (layout) {
                                    case 'heading':
                                        return (
                                            <Heading
                                                as={headingTag}
                                                fontKeys="Recife Display/24_120"
                                                key={key}
                                                margin={firstSection ? '0' : customMargin.md}
                                            >
                                                {heading}
                                            </Heading>
                                        );

                                    case 'subHeading':
                                        return (
                                            <Heading
                                                as={headingTag}
                                                fontKeys="Recife Display/20"
                                                key={key}
                                                margin={firstSection ? '0' : customMargin.sm}
                                            >
                                                {heading}
                                            </Heading>
                                        );

                                    case 'preamble':
                                        return (
                                            <Div margin={firstSection ? '0' : customMargin.md}>
                                                <Wysiwyg
                                                    key={key}
                                                    data={text}
                                                    textComponent={props => (
                                                        <Text
                                                            fontKeys={[
                                                                'Recife Display/22_140',
                                                                null,
                                                                'Recife Display/32_140',
                                                            ]}
                                                            {...props}
                                                        />
                                                    )}
                                                    tagComponents={{ url: TextLinkWysisyg }}
                                                />
                                            </Div>
                                        );

                                    case 'paragraph':
                                        return (
                                            <Div margin={firstSection ? '0' : customMargin.sm}>
                                                <Wysiwyg
                                                    key={key}
                                                    data={text}
                                                    textComponent={props => <Text {...props} />}
                                                    tagComponents={{ url: TextLinkWysisyg }}
                                                />
                                            </Div>
                                        );

                                    default:
                                        return null;
                                }
                            })}
                        </TextBlocksWrapper>
                    </Component>,
                ]}
            />
        </>
    );
};

TextSection.propTypes = {
    columnBase: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    columnSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    gutterWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    textAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    textSections: textContentProp,
};

export default TextSection;
