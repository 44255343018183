import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundVideo from 'components/base/video/BackgroundVideo';
import PropTypes from 'prop-types';
import React from 'react';
import ratios from 'config/theme/ratios';

const VideoInlineRatioBlock = ({ content = {} }) => {
    const { video_ratio: VideoRatio } = content;

    const video = VideoRatio.background_video.video;
    const ratio = VideoRatio.ratio === 'portrait' ? ratios.vertical : ratios.horizontal;

    if (!video) {
        return null;
    }

    return (
        <AspectWrapper ratio={ratio}>
            <BackgroundVideo src={video.url} />
        </AspectWrapper>
    );
};

VideoInlineRatioBlock.propTypes = {
    content: PropTypes.shape({
        'video_ratio': PropTypes.exact({
            'background_video': PropTypes.object,
            ratio: PropTypes.string,
        }),
    }).isRequired,
};

export default VideoInlineRatioBlock;
