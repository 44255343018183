import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledTag = styled('span')``;

const Tag = ({
    as = 'span',
    children,
    fontKeys = ['NB International/12_120_7', null, 'NB International/14_100_7'],
    ...rest
}) => (
    <StyledTag as={as} display="block" textTransform="uppercase" {...styleObjectToProps(fontKeys)} {...rest}>
        {children}
    </StyledTag>
);

Tag.propTypes = {
    as: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Tag;
