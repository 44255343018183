/**
 *
 * Use to get the height of multiple nodes, including margin. Returns a value
 *
 * @param {HTMLElement[]} nodes  - Array of nodes
 * @returns {number} - The combined height of all nodes, including margin as an integer.
 */
const getElementsHeights = (nodes: HTMLElement[]): number => {
    if (nodes?.length < 1) {
        return 0;
    }

    return Math.round(
        nodes.reduce((acc: number, child: HTMLElement) => {
            const { height, marginTop, marginBottom } = getComputedStyle(child);
            return acc + parseFloat(height) + parseFloat(marginTop) + parseFloat(marginBottom);
        }, 0)
    );
};

export default getElementsHeights;
